<script>
import ResetPasswordForm from "./ResetPasswordForm.vue";
import RequestPasswordResetForm from "./RequestPasswordResetForm.vue";

export default {
  name: "ResetPassword",
  components: { ResetPasswordForm, RequestPasswordResetForm },
  data() {
    return {
      isLoggedInByToken: true,
      passwordResetRequested: false,
      submittedEmail: null
    };
  },
  mounted() {
    if (!this.$auth.isAuthenticated) {
      this.loginUser();
    }
  },
  methods: {
    loginUser() {
      const { token: refreshToken } = this.$route.params;
      if (refreshToken) {
        this.$auth.refreshAccess({ refreshToken });
      } else {
        this.isLoggedInByToken = false;
      }
    },
    handleEmailSubmission(email) {
      this.submittedEmail = email;
      this.passwordResetRequested = true;
    }
  }
};
</script>

<template>
  <section>
    <div class="main-container">
      <div class="form-container">
        <ResetPasswordForm v-if="isLoggedInByToken" />
        <RequestPasswordResetForm v-else-if="!isLoggedInByToken && !passwordResetRequested" @emailSubmitted="handleEmailSubmission" />
        <VerificationForm
          v-else
          :email="submittedEmail"
          :instruction="'Reset your password by clicking the verification link we\'ve sent to your email. Or, enter the code from your verification email below.'"
          :submit-button-text="'Verify Code'"
          :redirect-location="'reset_password'"
          :success-snackbar-message="`An email has been sent to ${this.submittedEmail} with a link to reset your password.`"
          :form-error-toast-message="'Please enter the verification code you were sent to reset your password.'"
        />
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "../../scss/mixins.scss";
section {
  display: flex;
  justify-content: center;
  width: 100vw;
  .main-container {
    min-height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1700px;
    .form-container {
      border: 1px solid #dcdee9;
      background-color: white;
      max-width: 400px;
    }
    @include from($tablet) {
      min-height: 0;
      margin: 2rem;
      .form-container {
        margin: 3rem;
        flex: 1;
        width: 75vw;
      }
    }
  }
}
</style>
