<script>
import { RequestPasswordReset } from "@/models/actions/operations.gql";
import { required, email } from "vuelidate/lib/validators";
import { validateErrors } from "./validationErrors.js";
import { EMPTY_FIELD, INVALID_FORMAT, NOT_EXIST, UNSPECIFIED, DEFAULT_FALLBACK, getErrorMessage, fallbackErrorMessage } from "@/backendException.js";
import FormField from "./FormField.vue";
import FormInput from "./FormInput.vue";

export default {
  name: "RequestPasswordResetForm",
  components: { FormField, FormInput },
  emits: ["emailSubmitted"],
  data() {
    return {
      email: "",
      formErrorToast: null,
      validationReasonMap: new Map([
        [EMPTY_FIELD, "All fields must be filled out."],
        [INVALID_FORMAT, "Email format is invalid."],
        [NOT_EXIST, "An account with this email does not exist."],
        [DEFAULT_FALLBACK, fallbackErrorMessage]
      ]),
      executionReasonMap: new Map([
        [UNSPECIFIED, "Unable to send password reset token. Please try again or contact support if the issue persists."],
        [DEFAULT_FALLBACK, fallbackErrorMessage]
      ])
    };
  },
  beforeDestroy() {
    this.clearAlerts();
  },
  validations: {
    email: {
      required,
      email
    }
  },
  methods: {
    validateErrors,
    async submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.formErrorToast = this.$buefy.toast.open({
          message: "Please enter your email to request password reset.",
          type: "is-danger"
        });
      } else {
        try {
          const response = await this.$apollo.mutate({
            mutation: RequestPasswordReset,
            variables: {
              email: this.email
            }
          });
          const errors = response.data.result.errors;
          if (errors?.length > 0) {
            this.formErrorToast = this.$buefy.toast.open({
              message: getErrorMessage(errors, this.validationReasonMap, this.executionReasonMap),
              type: "is-danger"
            });
          } else {
            this.$emit("emailSubmitted", this.email);
          }
        } catch (error) {
          this.formErrorToast = this.$buefy.toast.open({
            message: fallbackErrorMessage,
            type: "is-danger"
          });
        }
      }
    },
    clearAlerts() {
      if (this.formErrorToast) {
        this.formErrorToast.close();
      }
    }
  }
};
</script>

<template>
  <form @submit.prevent="submitForm">
    <FormField name="email" label="Email:" :validation="$v.email" :hide-label="true" :error-message="validateErrors($v.email, 'Email')">
      <FormInput v-model.trim="$v.email.$model" placeholder="Email" type="email" />
    </FormField>
    <button type="submit">Request Password Reset</button>
  </form>
</template>

<style lang="scss" scoped>
@import "../../scss/mixins.scss";

form {
  display: flex;
  flex-direction: column;
  margin: 2.5rem;
  gap: 1.25rem;
  button {
    background-color: white;
    border: 1px solid $border;
    border-radius: 2px;
    color: $blue;
    font-family: $family-secondary;
    font-size: 1rem;
    font-weight: 500;
    height: auto;
    text-align: center;
    padding: 0.75rem;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      background-color: darken(white, 5%);
    }
  }
}
</style>
