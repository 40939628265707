<script>
import FormField from "./FormField.vue";
import FormInput from "./FormInput.vue";
import { validateErrors } from "./validationErrors.js";
import { required, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { ResetPassword } from "@/models/actions/operations.gql";
import { EMPTY_FIELD, INVALID_FORMAT, UPDATE_FAILED, DEFAULT_FALLBACK, getErrorMessage, fallbackErrorMessage } from "@/backendException.js";

export default {
  name: "ResetPasswordForm",
  components: { FormField, FormInput },
  data() {
    return {
      password: "",
      confirmPassword: "",
      passwordReset: false,
      successSnackbar: null,
      formErrorToast: null,
      validationReasonMap: new Map([
        [EMPTY_FIELD, "All fields must be filled out."],
        [INVALID_FORMAT, "Password format is invalid."],
        [DEFAULT_FALLBACK, fallbackErrorMessage]
      ]),
      executionReasonMap: new Map([
        [UPDATE_FAILED, "Unable to update password. Please try again or contact support if the issue persists."],
        [DEFAULT_FALLBACK, fallbackErrorMessage]
      ])
    };
  },
  computed: {
    validateConfirmPasswordError() {
      const genericError = validateErrors(this.$v.confirmPassword, "Password confirmation");
      if (genericError) return genericError;
      if (!this.$v.confirmPassword.sameAsPassword) return "Passwords must match.";
      return "";
    }
  },
  watch: {
    passwordReset() {
      this.clearAlerts();
    }
  },
  mounted() {
    this.successSnackbar = this.$buefy.snackbar.open({
      queue: false,
      message: "You have been verified! You can now reset your password.",
      position: "is-top",
      type: "is-success",
      actionText: null,
      indefinite: true
    });
  },
  beforeDestroy() {
    this.clearAlerts();
  },
  validations: {
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(200)
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs("password")
    }
  },
  methods: {
    validateErrors,
    async resetPassword() {
      try {
        const response = await this.$apollo.mutate({
          mutation: ResetPassword,
          variables: {
            userId: this.$auth.user.id,
            password: this.password
          }
        });
        const errors = response.data.result.errors;
        if (errors?.length > 0) {
          return { success: false, error: getErrorMessage(errors, this.validationReasonMap_resetPassword, this.executionReasonMap_resetPassword) };
        }
        return { success: true };
      } catch (error) {
        return { success: false, error: fallbackErrorMessage };
      }
    },
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.formErrorToast = this.$buefy.toast.open({
          message: "Please create new password and confirm to reset your password.",
          type: "is-danger"
        });
      } else {
        const resetPasswordResult = await this.resetPassword();
        if (!resetPasswordResult.success) {
          this.formErrorToast = this.$buefy.toast.open({
            message: resetPasswordResult.error,
            type: "is-danger"
          });
          return;
        }
        try {
          await this.$auth.logout({ redirectLocation: { name: "reset_password" } });
          this.passwordReset = true;
          this.clearAlerts();
        } catch (error) {
          this.formErrorToast = this.$buefy.toast.open({
            message: "Unable to redirect you to the login screen. Please navigate there to log in with your new password.",
            type: "is-danger"
          });
        }
      }
    },
    redirectToLogin() {
      this.$router.push({ name: "login" });
    },
    clearAlerts() {
      if (this.formErrorToast) {
        this.formErrorToast.close();
        this.formErrorToast = null;
      }
      if (this.successSnackbar) {
        this.successSnackbar.close();
        this.successSnackbar = null;
      }
    }
  }
};
</script>

<template>
  <div>
    <form v-if="!passwordReset" @submit.prevent="onSubmit">
      <FormField name="password" label="Password:" :validation="$v.password" :hide-label="true" :error-message="validateErrors($v.password, 'Password')">
        <FormInput v-model.trim="$v.password.$model" placeholder="Enter Password" type="password" />
      </FormField>
      <FormField name="confirmPassword" label="Confirm Password:" :validation="$v.confirmPassword" :hide-label="true" :error-message="validateConfirmPasswordError">
        <FormInput v-model.trim="$v.confirmPassword.$model" placeholder="Confirm Password" type="password" />
      </FormField>
      <button type="submit">Reset Password</button>
    </form>

    <form v-else @submit.prevent="redirectToLogin">
      <p>
        Your password was successfully reset! You have been logged out and must log back in to continue.
      </p>
      <button type="submit">Log In</button>
    </form>
  </div>
</template>

<style lang="scss" scoped>
@import "../../scss/mixins.scss";

form {
  display: flex;
  flex-direction: column;
  margin: 2.5rem;
  gap: 1.25rem;
  button {
    background-color: white;
    border: 1px solid $border;
    border-radius: 2px;
    color: $blue;
    font-family: $family-secondary;
    font-size: 1rem;
    font-weight: 500;
    height: auto;
    text-align: center;
    padding: 0.75rem;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      background-color: darken(white, 5%);
    }
  }
  p {
    text-align: center;
    font-size: 0.875rem;
  }
  .extra-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    p {
      font-size: 0.875rem;
    }
    a {
      font-size: 0.875rem;
    }
  }
  .success-message {
    color: green;
    font-weight: bold;
    text-align: center;
    margin-top: 1rem;
  }
  @include from($tablet) {
    .extra-links {
      a {
        font-size: 1.4rem;
      }
    }
  }
}
</style>
